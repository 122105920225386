import React, { useRef } from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import video from "./zkFHE-0810.mp4";
import Tag from "@src/components/Tag";
import SectionTitle from "@src/components/SectionTitle";
import Button from "@src/components/Button";
import SectionContent from "@src/components/SectionContent";
import useFadeIn from "@src/hooks/useFadeIn";
import zkfhe from "./zkfhe.svg";
import mpctls from "./mpctls.svg";
import { DocsLink } from "@src/constant/navLinks";
const title =
  "Unleash the potential of MPC-TLS and zkFHE";
const content =
  "Conduct data verification and provide proofs to ensure honest and accurate homomorphic operations, vital for trust in Web3 applications.";
const learnMoreLink = `${DocsLink}`;

const techItems = [
  {
    img: mpctls,
    title: "MPC-TLS",
    desc: "Enable trusted, verifiable data flows from Web2 to Web3",
    link: `${DocsLink}data-verification/tech-intro`,
  },
  {
    img: zkfhe,
    title: "zkFHE",
    desc: "Perform verifiable computations on any encrypted data",
    link: `${DocsLink}data-computation/understand-zkfhe-network`,
  },
];
export default () => {
  const ref = useRef(null);
  const videoRef = useRef(null);
  const [visible] = useFadeIn(ref, videoRef);
  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={cn(styles.img)}>
          <video
            ref={videoRef}
            className={styles.video}
            loop="loop"
            muted={true}
            playsInline={true}
            webkit-playsinline="true"
          >
            <source src={video} type="video/mp4"></source>
          </video>
        </div>
      </div>
      <div className={styles.right}>
        <SectionTitle
          transition
          visible={visible}
          ref={ref}
          className={styles.title}
        >
          {title}
        </SectionTitle>
        <div className={styles.desc}>
          <div>
            Primus uses secure MPC-TLS (multi-party computation for transport
            layer security) and zkFHE (zero-knowledge fully homomorphic
            encryption) to validate arbitrary web data and utilize it in an
            encrypted form.
          </div>
          <div>
            Cryptography ensures the reliability of data and service providers.
          </div>
        </div>
        <div className={styles.contentItems}>
          {techItems.map((i) => {
            return (
              <a className={styles.contentItem} href={i.link} target="_blank">
                <img className={styles.contentItemLeft} src={i.img} />
                <div className={styles.contentItemRight}>
                  <div className={styles.contentItemTitle}>{i.title}</div>
                  <div className={styles.contentItemDesc}>{i.desc}</div>
                </div>
              </a>
            );
          })}
        </div>
        <Button link={learnMoreLink} className={styles.button} isDark>
          Learn More
        </Button>
      </div>
    </div>
  );
};
