import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import SectionTitle from "@src/components/SectionTitle";
import useFadeIn from "@src/hooks/useFadeIn";
import { DiscordLink } from "@src/constant/navLinks";
import { DocsLink } from "@src/constant/navLinks";

const data = [
  {
    ask: "What is the basic installation requirements?",
    question: (
      <div className={styles.flexC}>
        <div>
          The Primus Extension is currently compatible with Chrome and
          Edge browsers. You can download it from the Chrome Web Store.
        </div>
        <div>
          For the Windows users, make sure to download the extension from the
          Chrome Web Store and install the latest version of the MetaMask wallet
          (a Chrome extension).
        </div>
      </div>
    ),
  },
  {
    ask: "What is the data verification process?",
    question: (
      <>
        Primus uses MPC-TLS and IZK to ensure web data authenticity, supported
        by independent research
        <a
          href="https://eprint.iacr.org/2023/964"
          target="_blank"
          className={styles.questionLink}
        >
          https://eprint.iacr.org/2023/964
        </a>{" "}
        , providing privacy-preserving data computation with integrity measures.
      </>
    ),
  },
  {
    ask: "How to ensure data security and privacy?",
    question:
      "With the Primus Extension, you retain full control over your data. Privacy settings are customizable, and your data remains isolated from smart contracts. The extension retrieves data solely for website logins, and all information is stored locally on your device, accessible only to you.",
  },
  {
    ask: "What is the rewards program?",
    question:
      "You can earn rewards by actively using the Primus Extension—the more you participate, the more rewards you can accumulate. No worries if you switch computers or browsers! Simply connect the same Web3 wallet (the address is displayed on the Settings page under “Account”) to your new Primus Extension. Your points and completed tasks will automatically synchronize, allowing you to pick up right where you left off.",
  },
  {
    ask: "How to participate an attestation campaign?",
    question:
      "Primus has several ongoing attestation campaigns for you to participate in and earn rewards. Head over to the Events page to explore currently active campaigns. Click on each campaign to view the details and tasks involved. Each campaign outlines specific tasks to be completed, often in a particular order.",
  },
];

const TutorialsLink = `${DocsLink}data-verification/pado-extension/extension-walkthrough/start`;

const Card = ({ index, ask, question }) => {
  const [visible, setVisible] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const handleClick = () => {
    const newVal = !visible;
    setVisible(newVal);
    if (newVal) {
      const { height } = ref.current.getBoundingClientRect();
      setHeight(height || "auto");
    } else {
      setHeight(0);
    }
  };
  return (
    <div className={styles.card} onClick={handleClick}>
      <div className={styles.cardHeader}>
        <div className={styles.no}>0{index + 1}</div>
        <div
          className={cn(styles.btn, "flex mobile-view", {
            [styles.up]: visible,
          })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="12"
            viewBox="0 0 20 12"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.792893 1.04289C1.18342 0.652369 1.81658 0.652368 2.20711 1.04289L10 8.83579L17.7929 1.04289C18.1834 0.652368 18.8166 0.652368 19.2071 1.04289C19.5976 1.43342 19.5976 2.06658 19.2071 2.45711L10.7071 10.9571C10.3166 11.3476 9.68342 11.3476 9.29289 10.9571L0.792893 2.45711C0.402369 2.06658 0.402368 1.43342 0.792893 1.04289Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div className={styles.body}>
        <div
          className={cn(styles.ask, {
            [styles.visible]: visible,
          })}
        >
          {ask}
        </div>
        <div style={{ height }} className={cn(styles.question)}>
          {question}
        </div>
        <div ref={ref} className={cn(styles.question, styles.questionCalc)}>
          {question}
        </div>
      </div>
      <div
        className={cn(styles.btn, "pc-view", {
          [styles.up]: visible,
        })}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="12"
          viewBox="0 0 20 12"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.792893 1.04289C1.18342 0.652369 1.81658 0.652368 2.20711 1.04289L10 8.83579L17.7929 1.04289C18.1834 0.652368 18.8166 0.652368 19.2071 1.04289C19.5976 1.43342 19.5976 2.06658 19.2071 2.45711L10.7071 10.9571C10.3166 11.3476 9.68342 11.3476 9.29289 10.9571L0.792893 2.45711C0.402369 2.06658 0.402368 1.43342 0.792893 1.04289Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default () => {
  const ref = useRef();
  const [visible] = useFadeIn(ref);
  return (
    <div className={styles.root}>
      <SectionTitle
        ref={ref}
        visible={visible}
        transition
        className={styles.title}
      >
        Frequently asked questions
      </SectionTitle>
      <div className={styles.textLine}>
        <span className={styles.text}>Find more support on</span>
        <a target="_blank" href={TutorialsLink} className={styles.link}>
          Tutorials
        </a>
        <span className={styles.text}>&</span>
        <a target="_blank" href={DiscordLink} className={styles.link}>
          Discord
        </a>
      </div>
      <div className={styles.cardWrapper}>
        {data.map((item, index) => (
          <Card key={index} index={index} {...item} />
        ))}
      </div>
    </div>
  );
};
