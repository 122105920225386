import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SectionTitle from "@src/components/SectionTitle";
import cn from "classnames";
import styles from "./index.module.scss";
import useFadeIn from "@src/hooks/useFadeIn";
import NewsTab from "@src/components/NewsTab";
import Button from "@src/components/Button";
import { news } from "@src/api/config";
import dayjs from "dayjs";
import { TYPEMAP } from "@src/config/constants";
const TAB_TYPE = {
  ALL: "All",
};

const tabs = [
  { type: TAB_TYPE.ALL },
  { type: "Blog" },
  { type: "Highlight" },
  { type: "News" },
  { type: "Use Case" },
];

const MainCard = ({ title, publishTime, content, pictureLink, link, type }) => {
  return (
    <div className={styles.mainCard}>
      <img alt={title} src={pictureLink} className={styles.cover} />
      <div className={styles.wrapper}>
        <div className={styles.infoWrapper}>
          <NewsTab disabled>{type}</NewsTab>
          <div className={"body-1-regular"}>{publishTime}</div>
        </div>
        <div className={cn("h3", styles.textEllipsis)}>{title}</div>
        <div className={cn("body-1-regular", styles.textEllipsis)}>
          {content}
        </div>
        <Button link={link} isDark className={styles.btn}>
          Learn More
        </Button>
      </div>
    </div>
  );
};

const NewsItemCard = ({ type, publishTime, pictureLink, title, link }) => {
  return (
    <a className={styles.newsItemCard} target="_blank" href={link}>
      <LazyLoadImage
        alt={title}
        src={pictureLink}
        wrapperClassName={styles.img}
      />
      <div>
        <div className={styles.infoWrapper}>
          <NewsTab disabled>{type}</NewsTab>
          <div className={"body-1-regular"}>{publishTime}</div>
        </div>
        <div className={styles.title}>{title}</div>
      </div>
    </a>
  );
};

export default () => {
  const ref = useRef(null);
  const [visible] = useFadeIn(ref);
  const [currentTab, setTab] = useState(tabs[0].type);
  const [main, setMain] = useState({});
  const [newsListData, setNewsListData] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const fetchNewsList = async () => {
    try {
      const { rc, msg, result } = await news();
      if (rc === 0) {
        let { newsList, slideNews } = result;
        
        slideNews.type = TYPEMAP[slideNews.type];
        slideNews.publishTime = dayjs(slideNews.publishTime).format(
          "MMM DD,YYYY"
        );
        newsList.items.forEach((i) => {
          if (i.publishTime) {
            i.publishTime = dayjs(i.publishTime).format("MMM DD,YYYY");
          }
          i.type = TYPEMAP[i.type];
        });
        const [lastItem, ...otherItems] = newsList.items;

        setMain(lastItem);
        setNewsList(otherItems);
        setNewsListData(otherItems);
      } else {
        alert(msg);
      }
    } catch (e) {}
  };
  useEffect(() => {
    fetchNewsList();
    // axios.get().then()
    // import('./data').then(({ default: res }) => {
    //     if (!res.data) return;
    //     const [first, ...moreNews] = res.data;
    //     setMain(first);
    //     setNewsList(moreNews);
    //     setNewsListData(moreNews);
    // })
  }, []);
  useEffect(() => {
    if (currentTab === TAB_TYPE.ALL) {
      setNewsList(newsListData);
      return;
    }
    const newList = newsListData.filter(({ type }) => type === currentTab);
    setNewsList(newList);
  }, [newsListData, currentTab]);
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.moduleHeader}>
          <SectionTitle visible={visible} ref={ref}>
            Insights
          </SectionTitle>
          <div className={styles.tabWrapper}>
            {tabs.map(({ type }) => {
              return (
                <NewsTab
                  onClick={() => setTab(type)}
                  key={type}
                  isActive={currentTab === type}
                >
                  {type}
                </NewsTab>
              );
            })}
          </div>
        </div>
        <MainCard {...main} />
        <div className={styles.cardListWrapper}>
          {newsList.map((item) => {
            return <NewsItemCard key={item.title} {...item} />;
          })}
        </div>
      </div>
    </div>
  );
};
