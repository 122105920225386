import MachineLearning from './assets/Machine-Learning.png';
import CreatorEconomy from './assets/Creator-Economy.png';
import ConfidentialVoting from './assets/Confidential-Voting.png';
import OnChainGames from './assets/On-chain-Games.png';
import ConfidentialPayment from "./assets/ConfidentialPayment.png";
import MachineLearningsS from "./assets/Machine-Learning-s.png";
import CreatorEconomyS from "./assets/Creator-Economy-s.png";
import ConfidentialVotingS from "./assets/Confidential-Voting-s.png";

import ConfidentialPaymentS from "./assets/ConfidentialPayment-s.png";
import { DocsLink } from "@src/constant/navLinks";

export const tabs = [
  {
    title: "AI-Driven Analytic & Confidential Prediction",
    subTitle: "Offers immediate infrastructure for secure data transactions",
    content:
      "Verified data integration via MPC-TLS today will evolve into privacy-preserving predictive analytics with zkFHE. Supporting Al-powered Web3 applications.",
    cover: MachineLearning,
    coverS: MachineLearningsS,
    link: `${DocsLink}data-computation/use-cases/single-user-mode/privacy-preserving-ai`,
  },
  {
    title: "Confidential Payment",
    subTitle:
      "Enable auditable anonymous and confidential payment for B2B payment",
    content:
      "zkFHE offers a solution for encrypting payment addresses, transaction amounts, and on-chain balances, enabling regulatory authorities to access transaction and balance data for compliance audits while keeping other payment details private.",
    cover: ConfidentialPayment,
    coverS: ConfidentialPaymentS,
    link: `${DocsLink}data-computation/use-cases/single-user-mode/confidential-payment`,
  },
  {
    title: "FHE Virtual Machine for AI",
    subTitle: "Supports various AI applications like NLP, CV, and even LLMs",
    content:
      "Provides developer-friendly FHE compiler and runtime environment toolkit with CPU/GPU supported. The virtual machine has high-performance cryptography algorithms integration, easy to use APIs and one-click deployment.",
    cover: CreatorEconomy,
    coverS: CreatorEconomyS,
    link: ``,
    // link: `${DocsLink}data-computation/use-cases/selective-multi-worker-mode/data-sharing`,
  },
  {
    title: "Consumer Escrow Protocol",
    subTitle: "Create consumer apps with verified ownership transfer",
    content:
      "MPC-TLS supports the tamper-proof data verification towards digital asset, and opens new possibilities to consumer apps such as digital marketplaces, on/off-ramps, prediction markets.",
    cover: ConfidentialVoting,
    coverS: ConfidentialVotingS,
    link: ``,
    // link: `${DocsLink}data-computation/use-cases/joint-worker-mode/voting`,
  },
  // {
  //   title: "On-Chain Games",
  //   subTitle:
  //     "Enhance application privacy to significantly boost the appeal of various gaming scenarios",
  //   content:
  //     "The combination of zkFHE with games can offer a trustless environment with necessary privacy measures and enhance gaming experiences by creating “fog-of-war” versions.",
  //   cover: OnChainGames,
  //   link: `${DocsLink}use-cases/threshold-fhe/game/`,
  // },
];